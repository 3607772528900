@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('./icons-fonts/icomoon.eot?42s34i');
  src: url('./icons-fonts/icomoon.eot?42s34i#iefix') format('embedded-opentype'),
  url('./icons-fonts/icomoon.ttf?42s34i') format('truetype'),
  url('./icons-fonts/icomoon.woff?42s34i') format('woff'),
  url('./icons-fonts/icomoon.svg?42s34i#icomoon') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  speak: never;
  text-transform: none;
}

.icon-timer:before {
  content: '\e98b';
}

.icon-store_mall_directory:before {
  content: '\e98a';
}

.icon-delivery_dining:before {
  content: '\e989';
}

.icon-location:before {
  content: '\e92c';
}

.icon-message:before {
  content: '\e900';
}

.icon-tiktok:before {
  content: '\e901';
}

.icon-menu-dots:before {
  content: '\e902';
}

.icon-tower:before {
  content: '\e903';
}

.icon-veggie-color:before {
  content: '\e906';
}

.icon-nuevo:before {
  content: '\e907';
}

.icon-promo:before {
  content: '\e908';
}

.icon-store-full:before {
  content: '\e909';
}

.icon-mail1:before {
  content: '\e90a';
}

.icon-person2:before {
  content: '\e90b';
}

.icon-spicy-color:before {
  content: '\e90c';
}

.icon-person:before {
  content: '\e90d';
}

.icon-stars-full:before {
  content: '\e90e';
}

.icon-pizza:before {
  content: '\e90f';
}

.icon-deal:before {
  content: '\e910';
}

.icon-soda:before {
  content: '\e911';
}

.icon-sticks:before {
  content: '\e912';
}

.icon-extra:before {
  content: '\e913';
}

.icon-order-full:before {
  content: '\e914';
}

.icon-menu-full:before {
  content: '\e915';
}

.icon-desert:before {
  content: '\e916';
}

.icon-shop:before {
  content: '\e917';
}

.icon-menu:before {
  content: '\e918';
}

.icon-stars:before {
  content: '\e919';
}

.icon-order:before {
  content: '\e91a';
}

.icon-user:before {
  content: '\e91b';
}

.icon-delivery:before {
  content: '\e91c';
}

.icon-add-circle:before {
  content: '\e91d';
}

.icon-add:before {
  content: '\e91e';
}

.icon-angle-down:before {
  content: '\e91f';
}

.icon-arrow-left:before {
  content: '\e920';
}

.icon-arrow-right:before {
  content: '\e921';
}

.icon-cart:before {
  content: '\e922';
}

.icon-clean-full:before {
  content: '\e923';
}

.icon-dropdown:before {
  content: '\e924';
}

.icon-delivery-full:before {
  content: '\e925';
}

.icon-dpto:before {
  content: '\e926';
}

.icon-facebook:before {
  content: '\e927';
}

.icon-google:before {
  content: '\e928';
}

.icon-phone1:before {
  content: '\e929';
}

.icon-instagram1:before {
  content: '\e92a';
}

.icon-local-full:before {
  content: '\e92b';
}

.icon-mail:before {
  content: '\e92d';
}

.icon-map:before {
  content: '\e92e';
}

.icon-note1:before {
  content: '\e92f';
}

.icon-pass-off:before {
  content: '\e930';
}

.icon-pass-on:before {
  content: '\e931';
}

.icon-password:before {
  content: '\e932';
}

.icon-phone:before {
  content: '\e933';
}

.icon-off:before {
  content: '\e934';
}

.icon-remove-circle:before {
  content: '\e935';
}

.icon-time:before {
  content: '\e936';
}

.icon-twitter:before {
  content: '\e937';
}

.icon-payment-cash:before {
  content: '\e938';
}

.icon-payment-debit-card:before {
  content: '\e939';
}

.icon-check:before {
  content: '\e93a';
}

.icon-cooking:before {
  content: '\e93b';
}

.icon-make-pizza:before {
  content: '\e93c';
}

.icon-oven:before {
  content: '\e93d';
}

.icon-pickup:before {
  content: '\e93e';
}

.icon-pizza-by-halves:before {
  content: '\e93f';
}

.icon-logout:before {
  content: '\e940';
}

.icon-star:before {
  content: '\e941';
}

.icon-cookie:before {
  content: '\e942';
}

.icon-cake:before {
  content: '\e943';
}

.icon-onepay:before {
  content: '\e944';
}

.icon-edit:before {
  content: '\e945';
}

.icon-time-full:before {
  content: '\e946';
}

.icon-check-simple:before {
  content: '\e947';
}

.icon-trash:before {
  content: '\e948';
}

.icon-error:before {
  content: '\e949';
}

.icon-heart1:before {
  content: '\e94a';
}

.icon-order-progress:before {
  content: '\e94b';
}

.icon-store-close:before {
  content: '\e94c';
}

.icon-attach:before {
  content: '\e94d';
}

.icon-image:before {
  content: '\e94e';
}

.icon-question:before {
  content: '\e94f';
}

.icon-posnet:before {
  content: '\e950';
}

.icon-search:before {
  content: '\e951';
}

.icon-hamburger:before {
  content: '\e952';
}

.icon-facebook-full:before {
  content: '\e953';
}

.icon-facebook-line:before {
  content: '\e954';
}

.icon-instagram-full:before {
  content: '\e955';
}

.icon-instagram-line:before {
  content: '\e956';
}

.icon-twitter-full:before {
  content: '\e957';
}

.icon-twitter-line:before {
  content: '\e958';
}

.icon-close-thick:before {
  content: '\e959';
}

.icon-close:before {
  content: '\e95a';
}

.icon-pay-online:before {
  content: '\e95b';
}

.icon-info:before {
  content: '\e95c';
}

.icon-person1:before {
  content: '\e95d';
}

.icon-spicy:before {
  content: '\e95e';
}

.icon-veggie:before {
  content: '\e95f';
}

.icon-switch-off:before {
  content: '\e960';
}

.icon-switch-on:before {
  content: '\e961';
}

.icon-save:before {
  content: '\e962';
}

.icon-cc-amex1:before {
  content: '\e964';
}

.icon-cc-discovery:before {
  content: '\e965';
}

.icon-cc-mastercard1:before {
  content: '\e966';
}

.icon-cc-visa1:before {
  content: '\e967';
}

.icon-copy:before {
  content: '\e968';
}

.icon-whatsapp:before {
  content: '\e969';
}

.icon-descount:before {
  content: '\e96a';
}

.icon-piece:before {
  content: '\e96b';
}

.icon-calendar:before {
  content: '\e96c';
}

.icon-flash:before {
  content: '\e96d';
}

.icon-check-off:before {
  content: '\e96e';
}

.icon-check-on:before {
  content: '\e96f';
}

.icon-angle-up:before {
  content: '\e970';
}

.icon-carrito-bolso:before {
  content: '\e971';
}

.icon-store-open:before {
  content: '\e972';
}

.icon-car:before {
  content: '\e973';
}

.icon-car_rounded:before {
  content: '\e974';
}

.icon-youtube:before {
  content: '\e975';
}

.icon-instagram:before {
  content: '\e976';
}

.icon-phone-full:before {
  content: '\e977';
}

.icon-clock:before {
  content: '\e978';
}

.icon-pin:before {
  content: '\e979';
}

.icon-ic_delivery:before {
  content: '\e97a';
}

.icon-phone-full1:before {
  content: '\e97b';
}

.icon-logout1:before {
  content: '\e97c';
}

.icon-tripadvisor:before {
  content: '\e97d';
}

.icon-notification:before {
  content: '\e97e';
}

.icon-credit-card:before {
  content: '\e97f';
}

.icon-currency-dollar:before {
  content: '\e980';
}

.icon-cc-discover-solid:before {
  content: '\e981';
}

.icon-cc-mastercard-solid:before {
  content: '\e982';
}

.icon-cc-visa-solid:before {
  content: '\e983';
}

.icon-cc-amex-solid:before {
  content: '\e984';
}

.icon-keyboard_arrow_right:before {
  content: '\e985';
}

.icon-keyboard_arrow_left1:before {
  content: '\e986';
}

.icon-keyboard_arrow_down:before {
  content: '\e987';
}

.icon-keyboard_arrow_up:before {
  content: '\e988';
}

.icon-home:before {
  content: '\e904';
}

.icon-office:before {
  content: '\e905';
}

.icon-location2:before {
  content: '\e963';
}

.icon-heart:before {
  content: '\e9da';
}
