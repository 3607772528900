@import 'src/scss/variables/variables';

.unlabeled-field {
  margin-top: 30px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.full-height {
  height: 100%; 
}

.half-height {
  height: 50%;
}

.no-pointer {
  cursor: auto;
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width: $small-device) {
  .full-width-mobile {
    width: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
}
