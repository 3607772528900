@import 'variables/colors';

@import 'variables/variables';

.button {
  border-radius: $default-border-radius;
  margin: 10px 0;
  padding: 15px;
  transition: all $transition-duration $transition-function;
  user-select: none;

  &:hover,
  &:active {
    color: $brand-secondary;

    .icon path {
      fill: $brand-secondary;
    }
  }

  &.primary {
    background-color: $brand-primary;
    color: $white;
    font-weight: 600;
    text-transform: uppercase;

    &:disabled {
      background-color: $alto;
      cursor: not-allowed;
    }

    &:hover:enabled,
    &:active:enabled {
      background-color: $brand-secondary;
      color: $white;
    }
  }

  &.secondary {
    border: 1px solid $brand-primary;
    border-radius: $default-border-radius;
    color: $brand-primary;

    &:hover,
    &:active {
      background-color: $brand-secondary;
      color: $white;
    }
  }

  &.default {
    background-color: $dusty-gray;
    border: none;
    border-radius: $default-border-radius;
    color: $white;

    &:hover,
    &:active {
      background-color: $dove-gray;
      color: $white;
    }

    &.no-background {
      background-color: transparent;
      border: 1px solid $dusty-gray;
      color: $dusty-gray;

      &:hover {
        border-color: $brand-primary;
      }
    }
  }

  &.menu-link {
    color: $white;
  }
}

.link {
  color: $brand-primary;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  user-select: text;

  &:hover:enabled,
  &:active:enabled {
    text-decoration: underline;
  }
}

.link--menu {
  font-weight: 600;
  text-align: left;
  text-decoration: none !important;
  padding: 10px 20px;
}

.link-inherit-size {
  font-size: inherit;
  line-height: inherit;
}
