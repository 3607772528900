@import './colors';
$transition-duration: 290ms;
$transition-function: cubic-bezier(0.790, 0.010, 0.375, 0.995);
$container-shadow: 0 2px 4px 0 rgba($black, 0.15);
$default-border-radius: 8px;
$content-max-width: 1440px;
$max-width-modal-default: 660px;
$max-width-modal-mini: 400px;
$navbar-height: 70px;
$navbar-padding: 10px;
$quick-transition-duration: 200ms;
$cart-container-width: 325px;
$modal-z-index: 5;
$topbar-z-index: 4;
$tooltip-z-index: $modal-z-index + 3;
$map-z-index: $topbar-z-index - 1;

// Media queries
$extra-small-device: 320px;
$extra-small-device-medium: 375px;
$extra-small-device-plus: 414px;
$extra-small-device-large: 425px;
$small-device: 550px;
$small-device-landscape: 667px;
$medium-device: 768px;
$desktop-device: 991px;
$desktop-device-landscape: 1024px;
$section-small-device-height: 54px;
$card-side-margin: 20px;
$desktop-breakpoint-generic: 1240px;

// Code verification
$code-input-count: 6;
$code-input-margin-right: 10px;
$code-input-width: 35px;
$code-verification-width: ($code-input-count * $code-input-width) + (($code-input-count - 1) * $code-input-margin-right);
$send-code-button-width: 370px;

// SearchBar
$search-bar-height: 73px;
$small-search-bar-height: 45px;

// Contact Form
$modal-address-height: 450px;

// JS Exports
:export {
  navbarHeight: $navbar-height;
};
$medium-device-plus: 860px;

// Navbar
$generic-navbar-height: 70px;
$small-generic-navbar-height: 50px;
$top-message-height: 40px;
$smartbanner-height: 60px;
