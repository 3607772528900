@import 'variables/variables';

@import 'variables/colors';

@import 'variables/fonts';

// Common text styles

.body-font {
  font-family: $bodyFont;
  font-weight: 500;
}

.category-bar-font {
  font-family: $categoryBarFont;
}

.subtitle-font {
  font-family: $subtitleFont;
}

.title-font {
  font-family: $titleFont;
}

.text-base {
  font-size: 16px;
  line-height: 19px;
}

.title {
  font-size: 30px;
  line-height: 36px;
}

.title-medium {
  font-size: 19px;
  line-height: 20px;
}

.title-large {
  font-family: $titleFont;
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
}

.subtitle-small {
  font-size: 12px;
  line-height: 15px;
}

.subtitle-medium {
  font-size: 14px;
  line-height: 30px;
}

.light-text {
  font-size: 12px;
}

.text-light {
  font-size: 14px;
  line-height: 17px;
}

.small-text {
  font-size: 11px;
  line-height: 16px;
}

.xsmall-text {
  font-size: 11px;
  line-height: 13px;
}

.tiny-text {
  font-size: 9px;
}

.big-text {
  font-size: 21px;
}

// Color texts

.text-charade {
  color: $charade;
}

.text-emperor {
  color: $emperor;
}

.text-brand-secondary {
  color: $brand-secondary;
}

.text-white {
  color: $white;
}

.text-brand-primary {
  color: $brand-primary;
}

.text-thunderbird {
  color: $thunderbird;
}

.text-silver {
  color: $silver;
}

.text-graphite {
  color: $graphite;
}

.text-emperor {
  color: $emperor;
}

.text-boulder {
  color: $boulder;
}

.text-dusty-gray {
  color: $dusty-gray;
}

.text-dove-gray {
  color: $dove-gray;
}

.text-red {
  color: $red;
}

.text-thundora {
  color: $thundora;
}

.text-dark-alto {
  color: $dark-alto;
}

.text-dim-gray {
  color: $dim-gray;
}

.text-green-observatory {
  color: $green-observatory;
}

// Font weights

.bold {
  font-weight: 600;
}

.regular {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

// Format text

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-justify {
  text-align: justify;
}

.text-start {
  text-align: start;
}

.line-through {
  text-decoration: line-through;
}

@for $i from 1 through 20 {
  .font-size-#{$i} {
    font-size: #{$i}px;
  }
}
