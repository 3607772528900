$transparent: transparent;
$white: #FFF;
$black: #000;
$black-tooltip: #222;
$azure: #35599F;
$valencia: #D34836;

// White
$wild-sand: #F5F5F5;
$alabaster: #FAFAFA;

// Green
$light-green: #C9E5D9;
$ocean-green: #3FAE7F;
$caribbean-green: #01BE7F;
$salem: #128D59;
$honeydew: #EDF7ED;
$cal-poly-pomona-green: #224924;
$may-green: #418944;


// Gray
$disabled-gray: #B1B1B1;
$mercury: #E7E7E7;
$whisper: #E5E5E5;
$harmony: #FAF2F3;
$alto: #DCDCDC;
$emperor: #555454;
$dove-gray: #6D7278;
$boulder: #7D7D7D;
$dim-gray: #6F6F6F;
$dusty-gray: #9B9B9B;
$silver: #C5C5C5;
$silver-chalice: #9D9D9D;
$silver-light: #DDD;
$thundora: #4A4A4A;
$graphite: #6D7278;
$gallery: #EFEFEF;
$gallery-light: #FBFBFB;
$heavy-metal: #232524;
$dark-alto: #D6D6D6;
$dark-medium: #D8D8D8;
$boulder: #7D7D7D;
$light-gray: #EDEDED;
$ford-gray: #979797;
$dark-gray: #555454;
$athens-gray: #F4F4F5;
$ghost-white: #F9F9F9;
$anti-flash-white: #F0F0F0;
$granity-gray: #616161;

// Black
$charade: #20242D;
$light-black: #00000014;
$japanese-indigo: #303E47;

// Red
$red: #FF0003;
$thunderbird: #BA151A;
$light-thunderbird: #FAA4A7;
$dark-tan: #7D0E14;
$carnation: #F95860;
$mandy: #E76365;
$mojo: #C84448;
$milano-red: #C10B0B;
$coral-red: #FF3E3E;
$linen: #FDEDED;
$caput-mortuum: #5F2120;
$light-carmine: #E06C6C;

// Yellow
$web-orange: #FAA300;
$selective-yellow: #F7B500;
$light-selective-yellow: #F8E4AB;
$old-lace: #FFF4E5;
$violin-brown: #663C00;
$royal-orange: #F2984C;

// Blue
$polar: #E5F7F9;
$rich-electric-blue: #1893D5;
$ateneo-blue: #014361; 
$bubbles: #E5F6FD;

// NPS Tooltip Gradient.
$agree-light: #73D78D;
$agree-dark: #009E5B;
$warning-light: #F1BE2C;
$warning-dark: #EF7C13;
$danger-light: #FD7D5F;
$danger-dark: #FC3A3A;

// Brand Colors
$brand-primary: var(--brand-primary);
$brand-secondary: var(--brand-secondary);
$brand-primary-rgb: var(--brand-primary-rgb);
$brand-secondary-rgb: var(--brand-secondary-rgb);
$green-observatory: #008F6D;
$concrete: #F2F2F2;
$roof-terracotta: #A11A28;
$terracotta-transparent: rgba(161, 26, 40, 0.15);
$silver: #C9C9C9;
$dusty-gray: #979797;
$catskill-white: #EFF4F7;
$alto-gray: #DADADA;
$shark: #2C2C31;
$tussock: #BE9639;
$cornflower: #9EBADE;
$emerald: #47C757;
$mystic: #D9E1EA;
