@import '~wolox-equalizer/equalizer';

@import '~font-awesome/css/font-awesome.min.css';

// TODO remove this library
@import 'variables/fonts';

@import 'variables/mixins';

@import 'animations';

@import 'icons';

@import 'layout';

@import 'margins';

@import 'paddings';

@import 'components';

@import 'fonts';

@import 'buttons';

@import 'variables/variables';

@import 'inputs';

* {
  font-family: $bodyFont;
}

body {
  background-color: $white;
  color: $dove-gray;
  height: auto;
  margin: auto;
  padding: 0;
}

.hide {
  display: none;
}

.unselectable {
  user-select: none;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

span {
  color: $black;
}

@media screen and (max-width: $small-device) {
  *,
  *:before,
  *:after {
    user-select: none;
  }

  input,
  input::before,
  input::after,
  textarea,
  textarea::before,
  textarea::after {
    user-select: initial;
  }

  img {
    -webkit-touch-callout: none;
  }
}

.pj-logo {
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  width: 130px;
}

#lemonpotWorkplace {
  display: none;
}

%backdrop-properties {
  background-color: rgba($black, 0.3);
  content: '';
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
input,
a,
button,
select,
textarea {
  display: initial;
}

.swiper-pagination {
  bottom: 20px !important;
  @include small {
    bottom: 10px !important;
  }
}

.swiper-button-next {
  background-color: $white;
  border-radius: 50px 0 0 50px;
  box-shadow: 0 5px 10px -4px rgba($black, 0.6);
  padding: 20px;
  right: 0!important;
  transition: padding 0.3s;

  &:after {
    color: $brand-primary;
    font-size: 20px !important;
    font-weight: bold;
  }

  &:hover {
    padding: 20px 40px 20px 20px;
  }
}

.swiper-button-prev {
  background-color: $white;
  border-radius: 0 50px 50px 0;
  box-shadow: 0 5px 10px -4px rgba($black, 0.6);
  left: 0 !important;
  padding: 20px;
  transition: padding 0.3s;

  &:after {
    color: $brand-primary;
    font-size: 20px !important;
    font-weight: bold;
  }

  &:hover {
    padding: 20px 20px 20px 40px;
  }
}
